import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import Header from "../Header/header";
import Back from "../Back/back";

const AIAssistantLearnerChatbot = () => {
 return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-sharp">text_fields</span>
                            <h1>AI Chatbot in LMS</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Click <a href="https://learningmate.instructure.com/courses/21545" target="_blank">here</a> to open the live demo in Canvas.</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-12 ">
                            <div className="wrapper-ai-case-content video-wraper">
                                <div className="videoPlayer">
                                    <video src={process.env.REACT_APP_AI_CHATBOAT_URL}  controls/>
                                </div>                                
                            </div>                            
                        </div>
                    </div>
                    <br />
                </div>
            </main>
        </div>

    )


}
export default AIAssistantLearnerChatbot;