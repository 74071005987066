import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import axios from 'axios';
import Header from "../Header/header";
import Back from "../Back/back";
import Loader from "../Loader";
import 'react-toastify/dist/ReactToastify.css';
import File_Uploader from '../FileUpload/fileupload';
import Toaster from "../Toaster/Toaster";

const ContentCuration = () => {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [textOnly, setTextOnly] = useState(null);
    const [textAndTable, setTextAndTable] = useState(null);
    const [keyOnly, setKeyOnly] = useState(null);
    const [selectedValue, setSelectedValue] = useState('text-based');
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');
    const curationTypes = [
        { id: 'text-based', label: 'Text Only', url: process.env.REACT_APP_CONTENTCURATION_FIRST_URL },
        { id: 'key point', label: 'Key Point', url: process.env.REACT_APP_CONTENTCURATION_SECOND_URL },
        { id: 'table-based', label: 'Text + Table', url: process.env.REACT_APP_CONTENTCURATION_THIRD_URL },
    ];

    //Reset data
    const resetData = async () => {
        setText('');
        setLoading(false);
        setTextOnly(null);
        setTextAndTable(null);
        setKeyOnly(null);
        setSelectedValue('text-based');
    }

    const uploadedTextData = (text1) => {
        // setReadOnly(true);
        setText(text1);
    }

    const formData = {
        text: text,
    }
    const callApi = async (url) => {
        let data = [];
        try {
            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, /'
                },
            }).then((response) => {
                data = response.data.data;
            });
        } catch (error) {
            console.log('error', error);
        }

        return data;
    }

    const postData = async () => {
        if (formData.text == '') {
            setMode("error");
            setMessage("Please type or paste or select a file for processing.");
        } else {
            let hasValue = false;
            setLoading(true);
            setTextOnly(null);
            setKeyOnly(null);
            setTextAndTable(null);
            try {
                let selectedType = curationTypes.find(x => x.id === selectedValue);
                const updates = await callApi(selectedType.url);
                
                if (selectedValue === 'text-based') { setTextOnly(updates.content_curation_text_based); hasValue = true;}
                else if (selectedValue === 'key point') { setKeyOnly(updates.content_curation_key_point); hasValue = true;}
                else if (selectedValue === 'table-based') {

                    if (Array.isArray(updates.content_curation_table_based.table_data.column_names) === false) {
                        updates.content_curation_table_based.table_data.column_names = updates.content_curation_table_based.table_data.column_names.split(',');
                        hasValue = true;
                    }
                    if (updates.content_curation_table_based.table_data.column_values.length === updates.content_curation_table_based.table_data.column_names.length) {
                        updates.content_curation_table_based.table_data.column_values = [updates.content_curation_table_based.table_data.column_values];
                        hasValue = true;
                    }
                    setTextAndTable(updates.content_curation_table_based)
                }
            } catch (error) {
                console.log('error', error);
                setMode("error");
                setMessage("Error while process the request.")
            }
            setLoading(false);
            if (hasValue){
                setMode("success");
                setMessage("Please scroll down to see the generated result.");
            }else{
                setMode("error");
                setMessage(process.env.REACT_APP_NO_DATA);
            }
        }
    }

    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center content-name">
                            {/* <span className="material-icons">&nbsp;</span> */}
                            <span className="material-symbols-sharp">text_fields</span>                            <h1>Content Curation</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Create Content on any topic with ease.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="wrapper-ai-case-content summarization_area" >
                                <div className="inputBox">
                                    <label htmlFor="question_drop">Add Content</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <textarea maxLength={process.env.REACT_APP_TEXT_LIMIT} className="form-control" value={text} placeholder="Paste your Text here" id="question_drop" style={{ "minHeight": "170px" }} onChange={(e) => setText(e.target.value)}></textarea>
                                        <div className="typedCount">You've typed {text.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>

                                        <div className="lines"><span>OR</span></div>
                                        <File_Uploader onFileUpload={uploadedTextData} />
                                    </div>
                                </div>
                                <div className="inputBox  content-curation_drop">
                                    <label htmlFor="question_drop">Screen Type</label>
                                    <div className=" multipleSelection">
                                    <select className="form-select mb-3" aria-label="" id="question_drop" value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)} >
                                                {curationTypes?.map((option) => {
                                                    return <option key={option.id} value={option.id}>{option.label}</option>
                                                })}
                                            </select>
                                    </div>
                                </div>
                                <div className="inputBox d-flex justify-content-end BtnBox">
                                    <button type="reset" className="btn btn-primary btn_reset" onClick={resetData}>Reset</button>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Generate</button>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </main>


            <div className="contents-part mt-3">
                <div className="container">
                    {textOnly ?
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="contextual-alt-area summarization_area" style={{ "minHeight": "280px" }} >
                                    <div className="inputBox">
                                        <p>Generated Result </p>
                                        <div className="wrapper-box content-curation-wrapper" >
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <p>Text only</p>
                                                        </button>
                                                    </h2><div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="accordion-body-inner-box">
                                                                <h3>Q. {textOnly?.question}</h3>
                                                                <h3 className="mt-3">Answer:</h3>
                                                                <ul className="m-3">
                                                                    {
                                                                        textOnly?.bullet_points?.map((item, index) => (
                                                                            <li key={index}>{item}</li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""}
                    {keyOnly ?
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="contextual-alt-area summarization_area" style={{ "minHeight": "280px" }} >
                                    <div className="inputBox">
                                        <p>Generated Result </p>
                                        <div className="wrapper-box content-curation-wrapper" >
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <p>Key Point</p>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="accordion-body-inner-box">
                                                                <div className="wrapper-box content-curation-wrapper key_point" >
                                                                    <p>{keyOnly?.key_point}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""}
                    {textAndTable ?
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="contextual-alt-area summarization_area" style={{ "minHeight": "280px" }} >
                                    <div className="inputBox">
                                        <p>Generated Result </p>
                                        <div className="wrapper-box content-curation-wrapper" >
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <p>Text + Table</p>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="accordion-body-inner-box">
                                                                <h3>Q. {textAndTable?.question}</h3>
                                                                <h3 className="mt-3">Answer: <span>{textAndTable?.answer}</span></h3>
                                                                <table className="table table-bordered mt-3">
                                                                    <thead>
                                                                        <tr>
                                                                            {
                                                                                textAndTable?.table_data.column_names?.map((item, index) => (
                                                                                    <th key={index}>{item}</th>
                                                                                ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {

                                                                            textAndTable?.table_data.column_values?.map((item, index) => (
                                                                                <tr>{
                                                                                    item?.map((inner2, index2) => (
                                                                                        <td key={index2}>{inner2}</td>
                                                                                    ))

                                                                                }</tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""}
                    {
                        textOnly || keyOnly || textAndTable ?
                            <div className="inputBox d-flex justify-content-end BtnBox">
                                <button type="reset" className="btn btn-primary btn_reset">Reset</button>
                                <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Regenerate</button>
                            </div>
                            : ""
                    }
                    <br />
                </div>
            </div>


            {loading ? <Loader /> : ""}
            <Toaster message={message} mode={mode}/>

        </div>
    )
}

export default ContentCuration;