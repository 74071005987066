import React, { useState } from "react";
const File_Uploader = ({ onFileUpload }) => {
    const fileReader = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const text = await readFile(file);
                onFileUpload(text)
            } catch (error) {
                console.error("Error reading file:", error);
            }
        }
    }
    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            }
            reader.onerror = (errors) => {
                reject(errors.target.error)
            };
            reader.readAsText(file);
        });
    }

    return (
        <div>
            <div className="mb-3 file-uploader">
                <label htmlFor="file-input" className="form-label ">Choose File</label>
                <input className="form-control choose-file" type="file" id="file-input" accept='.txt' onChange={(e) => fileReader(e)} />
            </div>
        </div>
    )
}

export default File_Uploader;