import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes } from "react-router-dom";
import AuthRoutes from './routes/AuthRoutes';
import UnAuthRoutes from './routes/UnAuthRoutes';
import './index.css';
import './all.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import * as serviceWorker from './serviceWorker';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {AuthRoutes}
        {UnAuthRoutes}
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
  <App />
</GoogleOAuthProvider>
);

serviceWorker.unregister();