import React, { useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import Header from "../Header/header";
import Back from "../Back/back";
import 'react-toastify/dist/ReactToastify.css';
import File_Uploader from '../FileUpload/fileupload';
import Toaster from "../Toaster/Toaster";

const Flashcard = () => {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [data1, setData1] = useState([]);
    //const [next, setNext] = useState(0);
    const [showData, setShowData] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');


    const nextClick = () => {
        if (currentIndex < data1.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    }

    const ToggleData = () => {
        setShowData(!showData);
        console.log("showdata", showData);
    }
    const uploadedTextData = (text1) => {
        // setReadOnly(true);
        setText(text1);
    }

    const resetData = () => {
        setText('');
        setLoading(false);
        setMessage("Please scroll down to see the generated result.");
        setData1([]);
        //setNext(false);
        setShowData(false);
        setShowResult(false);
    }

    const formData = {
        "text": text
    }

    const callApi = async (url) => {
        let data = [];
        try {
            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, /'
                },
            }).then((response) => {
                data = response.data.data;
                //setData1(response.data.data.term_definition);
            });

        } catch (error) {
            console.log('error', error);
        }
        setLoading(false);
        return data;
    }

    const postData = async () => {
        try {
            if (formData.text == '') {
                setMode("error");
                setMessage("Please type or paste or select a file for processing.");
            } else {
                setLoading(true);
                setShowResult(false);
                const response = await callApi(process.env.REACT_APP_FLASHCARD_URL);
                setData1(response.term_definition);
                setLoading(false);
                if (data1 && data1.length > 0){
                    setShowResult(true);
                    setMode("success");
                    setMessage("Please scroll down to see the generated result.");
                }else{
                    setMode("error");
                    setMessage(process.env.REACT_APP_NO_DATA);
                }
            }
        } catch (error) {
            setShowResult(false);
            setMode("error");
            setMessage("Error while process the request.");
        }
        //console.log("respons", data1);
    }


    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-sharp">style</span>
                            <h1>Flashcard</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Generate flashcards that cover key terms and definition.</p>
                        </div>
                    </div>
                    {/* <!--Loading Animation Lottiefiles-->
            <!-- <div className="row">
                <div className="col">
                    <div className="content-input-area" style="border-radius: 8px;border: 1px solid #d7d7d7; padding: 15px 30px;margin-bottom: 30px;">
                        <lottie-player src="animation_llw5xr91.json" background="transparent" speed="1" style="width: 338px; height: 338px;" autoplay></lottie-player>
                    </div>
                </div>
            </div> -->
            <!--end of loading animation--> */}
                    {loading ? <Loader /> : ""}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="wrapper-ai-case-content summarization_area" >
                                <div className="inputBox">
                                    <label htmlFor="question_drop">Add Content</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <textarea className="form-control" value={text} placeholder="Paste your Text here" id="question_drop" style={{ "minHeight": "170px" }} onChange={(e) => setText(e.target.value)} maxLength={process.env.REACT_APP_TEXT_LIMIT}></textarea>
                                        <div className="typedCount">You've typed {text.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>
                                        <div className="lines"><span>OR</span></div>
                                        <File_Uploader onFileUpload={uploadedTextData} />
                                    </div>
                                </div>
                                <div className="inputBox d-flex justify-content-end BtnBox">
                                    <button type="reset" className="btn btn-primary btn_reset" onClick={resetData}>Reset</button>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Generate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {data1 && showResult && data1.length > 0 ?
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="wrapper-ai-case-content summarization_area flashcard-content-box"  >
                                    <div className="inputBox">
                                        <p>Generated Result</p>
                                        {/* {data1.map((item, index) => ( */}
                                        <div className="flashcard-content">
                                            {showData ?
                                                (<div>
                                                    <p className="reveal_content">{data1[currentIndex].definition}</p>
                                                    <div className="BtnBox">
                                                        <button type="button" className="btn btn-primary btn_reveal" onClick={ToggleData}>Hide Definition</button>
                                                    </div>
                                                </div>) :
                                                (<div>
                                                    <p>{data1[currentIndex].term}</p>
                                                    <div>
                                                        <button type="button" className="btn btn-primary btn_reveal" onClick={ToggleData}>Reveal Definition</button>
                                                    </div>
                                                </div>)}
                                        </div>
                                        {/* ))} */}
                                        <div className="content-slide">
                                            <button className="">
                                                <span className="material-symbols-rounded" onClick={prevClick}>arrow_circle_left</span>
                                            </button>
                                            <p>{currentIndex + 1}/{data1.length}</p>
                                            <button className="" >
                                                <span className="material-symbols-rounded" onClick={nextClick}>arrow_circle_right</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""

                    }
                    {
                        showResult && data1.length < 1 ?
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="wrapper-ai-case-content summarization_area flashcard-content-box" style={{ "minHeight": "0" }} >
                                        <p>Generated Result</p>
                                        <div className="flashcard-content p-0" key="noresult">
                                            No keyword(s) found. Please validate the paragraph.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""}


                </div>
                <br />
            </main>
            {loading ? <Loader /> : ""}
            <Toaster message={message} mode={mode}/>
        </div>
    )
}

export default Flashcard;