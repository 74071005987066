import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import Header from "../Header/header";

const UnderDevelopment = () => {
    return (
        <div>
            <Header />
            <h2 className="under-development mt-5">This exciting feature is currently in development.</h2>
        </div>
    )
}
export default UnderDevelopment;