import { React } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = () => {
    let loading = true;
    const cssProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "blue",
    };
    return(
        <div className="sweet-loading">
                    <ClipLoader
                        loading={loading}
                        cssOverride={cssProperties}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader" />
                </div> 
    )
}
export default Loader;