import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import Header from "../Header/header";
import Back from "../Back/back";
const UserGuide = () => {
    

    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-sharp">text_fields</span>
                            <h1>User Guide</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>User guide for AI demo site</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-12 ">
                    <iframe src={process.env.REACT_APP_USER_GUIDE_URL} name="iframe_a" height="100%" width="100%" title="User Guide"></iframe>                        
                        </div>
                    </div>
                    <br />
                </div>
            </main>
        </div>
    )
}
export default UserGuide;