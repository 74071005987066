import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import Header from "../Header/header";
import axios from 'axios';
import Loader from "../Loader"
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Back from "../Back/back";
import 'react-toastify/dist/ReactToastify.css';
import Toaster from "../Toaster/Toaster";

const ImageAlt = () => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState("0");
    const [showResult, setShowResult] = useState(false);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');

    useEffect(() => {
        const dropArea = document.querySelector(".drag-area"),
            dragText = dropArea.querySelector("h2");
        //If user Drag File Over DropArea
        dropArea.addEventListener("dragover", (event) => {
            event.preventDefault(); //preventing from default behaviour
            dropArea.classList.add("active");
            dragText.textContent = "Release to Upload File";
        });

        //If user leave dragged File from DropArea
        dropArea.addEventListener("dragleave", () => {
            dropArea.classList.remove("active");
            dragText.textContent = "Drag or upload your own images";
        });

        //If user drop File on DropArea
        dropArea.addEventListener("drop", (event) => {
            event.preventDefault();
            setSelectedFile(Array.from(event.dataTransfer.files));
        });
    });

    const setSelectedFile = (files) => {
        const dropArea = document.querySelector(".drag-area"),
            dragText = dropArea.querySelector("h2");
        setShowResult(false);
        setSelectedImages(files)
        if (files.length > 0) {
            const imgArray = Array.from(files);
            const imgUrl = imgArray.map((f) => {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.onload = (event) => {
                        resolve(event.target.result);
                    };
                    reader.readAsDataURL(f);
                });
            });
            Promise.all(imgUrl).then((urls) => {
                let imgs = [];
                urls.forEach(url => {
                    let details = { url: url, caption: "", tag: "" }
                    imgs.push(details);
                });
                setImages(imgs);
            });

            let name = []
            imgArray.forEach(element => {
                name.push(element.name);
            });
            dragText.textContent = name.join(', ');//"Drag & Drop to Upload File";
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //image show
    const handleImageSelect = (e) => {
        setSelectedFile(Array.from(e.target.files))
    }
    //image upload
    const handleButton = () => {
        document.getElementById('image-input').click();
    }

    const generateImg = async () => {
        if (selectedImages.length === 0) {
            setMode("error");
            setMessage("Please an image for processing.");
        } else {
            setLoading(true);

            const formData = new FormData();
            selectedImages.forEach(file => {
                formData.append('files', file);
            });


            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            await axios.post(process.env.REACT_APP_IMAGE_ALT_TEXT_URL, formData, config).then((response) => {
                const updatedResponse = response.data.data.map((res, index) => ({
                    res,
                    index
                }));
                let updatedImages = [];
                updatedResponse.forEach(element => {
                    let details = { url: images[element.index].url, caption: updatedResponse[element.index].res.caption, tag: updatedResponse[element.index].res.tag }
                    updatedImages.push(details)
                });
                setImages(updatedImages);
                setLoading(false);

                if (updatedImages && updatedImages.length > 0){
                    setMode("success");
                    setMessage("Please scroll down to see the generated result.");
                }else{
                    setMode("error");
                    setMessage(process.env.REACT_APP_NO_DATA);
                }

                setShowResult(true);
            }).catch(error => {
                setShowResult(false);
                setMode("error");
                setMessage("Error while process the request.");
            });
        }
    }

    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-sharp">add_photo_alternate</span>
                            <h1>Image Alt Text</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Generate alt text for any images in a few clicks with this simple and effective tool!</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <div className="content-drag-area">
                                <div className="drag-area">
                                    <h2>Drag or upload your own images</h2>
                                    <button type="button" onClick={handleButton}>Upload Images</button>
                                    <input type="file" id="image-input" accept="image/*" onChange={(e) => handleImageSelect(e)} multiple hidden />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="container">
                            <div className="col">
                                <div className="inputBox d-flex justify-content-end BtnBox" style={{ "marginTop": "0" }}>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={generateImg}>Generate </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showResult && images.length > 0 ?
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="wrapper-ai-case-content my-3" >
                                        <Box key="box" sx={{ width: '100%', typography: 'body1' }}>
                                            <TabContext key="TabContext" value={value.toString()}>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList key="TabList" onChange={handleChange} aria-label="lab API tabs example">
                                                        {images.map((img, index) => (
                                                            <Tab key={`Tab${index}`} label={`Image: ${index + 1}`} value={index.toString()} />
                                                        ))}
                                                    </TabList>
                                                </Box>
                                                {images.map((img, index) => (
                                                    <TabPanel key={`TabPanel${index}`} value={index.toString()}>

                                                        <div className="img-all-content-area">
                                                            <div className="leftDiv">
                                                                <img key={`image${index}`} src={img.url} alt={`Uploaded ${index + 1}`} className="img-fluid" />

                                                            </div>
                                                            <div className="rightDiv">
                                                                <p>
                                                                    <span><b>Caption:</b> {img?.caption}</span><br />
                                                                    <span><b>Tag:</b> {img?.tag}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </TabPanel>

                                                ))}
                                            </TabContext>
                                        </Box>
                                    </div>
                                </div>
                            </div> : ""

                    }


                </div>

            </main>
            {
                loading ? <Loader /> : ""
            }
            <Toaster message={message} mode={mode}/>
        </div>

    )

}
export default ImageAlt;