import React from "react";
import Header from "../Header/header";
import { useState } from "react";
import Back from "../Back/back";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import Toaster from "../Toaster/Toaster";
import Loader from "../Loader";
import AsyncStorage from '@react-native-async-storage/async-storage';

const LoGeneration = () => {
    const readability = [{ id: "Grade1" }, { id: "Grade2" }, { id: "Grade3" }, { id: "Grade4" }, { id: "Grade5" }, { id: "Grade6" }, { id: "Grade7" }, { id: "Grade8" }, { id: "Grade9" }, { id: "High School" }];
    const [text, setText] = useState('');
    const [state, setState] = useState();
    const [learning, setLearning] = useState();
    const [sample, setSample] = useState('');
    const [readAbility, setReadAbility] = useState("Grade1");
    const [loading, setLoading] = useState(false);
    const [data1, setData1] = useState();
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');
    const [target1, setTarget] = useState('');

    const formData = {
        "state_standard": state,
        "Learning_goal": learning,
        "readability_level": readAbility,
        "text_references": text,
        "output_format": sample
    }

    const callApi = async (url) => {
        let data = [];
        try {
            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, /'
                },
            }).then((response) => {
                data = response.data.data["Lo_generate:"];
            });

        } catch (error) {
            console.log('error', error);
        }
        setLoading(false);
        return data;
    }

    const postData = async () => {
        try {
            setLoading(true);
            const response = await callApi(process.env.REACT_APP_LOGENERATION_URL);
            setData1(response);
            setLoading(false);
            setTarget(response);
            if (response) {
                setMode("success");
                setMessage("Please scroll down to see the generated result.");
            } else {
                setMode("error");
                setMessage(process.env.REACT_APP_NO_DATA);
            }

        } catch (error) {
            setMode("error");
            setMessage("Error while process the request.");
        }


    }
    const postData1 = async () => {
        await AsyncStorage.setItem('text', target1);
        await AsyncStorage.setItem('learning_gole', learning);
        window.location = "/assessment";
    }

    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    {loading ? <Loader /> : ""}
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center content-name">
                            {/* <span className="material-icons">quiz</span> */}
                            <span className="material-symbols-sharp">text_fields</span>
                            <h1>LO Generator</h1>
                        </div>
                        {/* <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Create Assessment on any topic with ease.</p>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="wrapper-ai-case-content summarization_area" >
                                <div className="inputBox">
                                    <label htmlFor="learning_object">State Standarad</label>
                                    <input type='text' className="form-control" value={state} placeholder="" id="learning_object" onChange={(e) => setState(e.target.value)} />
                                    <label htmlFor="learning_object">Learning Goal</label>
                                    <input type='text' className="form-control" value={learning} placeholder="" id="learning_object" onChange={(e) => setLearning(e.target.value)} />
                                    <div className="inputBox  content-curation_drop">
                                        <label htmlFor="qst_type">Readability Level</label>
                                        <div className=" multipleSelection">
                                            <div className="selectBox">
                                                <select className="form-select form-select-lg mb-3" aria-label="" id="question_drop" value={readAbility} onChange={(e) => setReadAbility(e.target.value)} >
                                                    {readability.map((option) => {
                                                        return <option key={option.id} value={option.id}>{option.id}</option>
                                                    })}
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="inputBox  content-curation_drop">
                                    <label htmlFor="question_drop">Text References</label>
                                    <textarea maxLength={process.env.REACT_APP_TEXT_LIMIT} className="form-control" value={text} placeholder="Paste your Text here" id="add_content" style={{ "minHeight": "170px" }} onChange={(e) => setText(e.target.value)}></textarea>
                                    <div className="typedCount">You've typed {text.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>
                                </div>
                                <div className="inputBox  content-curation_drop">
                                    <label htmlFor="question_drop">Sample Format</label>
                                    <textarea maxLength={process.env.REACT_APP_TEXT_LIMIT} className="form-control" value={sample} placeholder="Paste your Text here" id="add_content" style={{ "minHeight": "170px" }} onChange={(e) => setSample(e.target.value)}></textarea>
                                    <div className="typedCount">You've typed {sample.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>
                                </div>
                                <div className="inputBox d-flex justify-content-end BtnBox">
                                    <button type="reset" className="btn btn-primary btn_reset">Reset</button>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Generate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {
                data1 ?
                    <div className="contents-part m-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="wrapper-ai-case-content summarization_area" style={{ "minHeight": "280px" }} >
                                        <div className="inputBox">
                                            <p>LO Content</p>

                                            <div className="summarized-content"  >
                                                <textarea value={data1} maxLength={process.env.REACT_APP_TEXT_LIMIT} onChange={(e) => setTarget(e.target.value)}></textarea>
                                                <div className="typedCount">You've typed {target1.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>
                                            </div>
                                            <div className="inputBox d-flex justify-content-end BtnBox">
                                                <button type="button" className="btn btn-primary btn_generate" onClick={postData1}>Generate Assessment</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> : ""
            }
            {loading ? <Loader /> : ""}
            <Toaster message={message} mode={mode} />
        </div>
    )
}

export default LoGeneration;