import React from "react";
import { Route } from "react-router-dom";
import AuthGuard from "../guards/AuthGuards";
import Login from '../pages/Login/login'
import Home from '../pages/Home/home';
import  QuestionAnswer  from '../pages/QuestionAnswer/question-answer';
import Summarization from "../pages/Summarization/summarization";
import SuggestiveKeyword from "../pages/SuggestiveKeyword/suggestivekeyword";
import AutoGrading from "../pages/Auto-Grading-assistant/autogradingassistant";
import ImageAlt from "../pages/Image-Alt/imageAlt";
import ContentCuration from "../pages/Contentcuration/contentcuration";
import ContextualAlttext from "../pages/ContextualAlttext/contextualalttext";
import UnderDevelopment from "../pages/UnderDevelopment/under-development";
import AIAssistantLearnerChatbot from "../pages/AIAssistantLearnerChatbot/AIAssistantLearnerChatbot";
import AIAssistantCurriculum from "../pages/AIAssistantCurriculum/AIAssistantCurriculum";
import AIAssistantQuestionAnswerGenerator from "../pages/AIAssistantQuestionAnswerGenerator/AIAssistantQuestionAnswerGenerator";
import Assessment from "../pages/Assessment/assessment";
import Flashcard from "../pages/Flashcard/flashcard";
import LoGeneration from "../pages/LOGeneration/LoGeneration";
import UserGuide from "../pages/UserGuide/UserGuide";
import OCR from "../pages/OCR/OCR";

const AuthRoutes = [
    <Route key="Home" path="/" element={<Home />} />,
    <Route key="Login" path="login" element={<Login />} />,
    <Route key="Home" path="/home" element={<Home />} />,
    <Route key="UserGuide" path="/user-guide" element={<UserGuide />} />,
    <Route key="OCR" path="/ocr" element={<AuthGuard component={<OCR />} />} />,
    <Route key="QuestionAnswer" path="/question-answer" element={<AuthGuard component={<QuestionAnswer />} />} />,
    <Route key="Summarization" path="/summarization" element={<AuthGuard component={<Summarization />} />} />,
    <Route key="SuggestiveKeyword" path="/suggestive-Keyword" element={<AuthGuard component={<SuggestiveKeyword />} />} />,
    <Route key="AutoGrading" path="/grading-assistant" element={<AuthGuard component={<AutoGrading />} />} />,
    <Route key="ImageAlt" path="/image-alt-text" element={<AuthGuard component={<ImageAlt />} />} />,
    <Route key="ContentCuration" path="/content-curation" element={<AuthGuard component={<ContentCuration />} />} />,
    <Route key="AIAssistantCurriculum" path="/ai-assistant-curriculum" element={<AuthGuard component={<AIAssistantCurriculum />} />} />,
    <Route key="AIAssistantLearnerChatbot" path="/ai-assistant-learner-chatbot" element={<AuthGuard component={<AIAssistantLearnerChatbot />} />} />,
    <Route key="AIAssistantQuestionAnswerGenerator" path="/ai-assistant-question-answer-generator" element={<AuthGuard component={<AIAssistantQuestionAnswerGenerator />} />} />,
    <Route key="ContextualAlttext" path="/contextual-alt-text" element={<AuthGuard component={<ContextualAlttext />} />} />,
    <Route key="UnderDevelopment" path="/under-development" element={<AuthGuard component={<UnderDevelopment />} />} />,
    <Route key="Assessment" path="/assessment" element={<AuthGuard component={<Assessment />} />} />,
    <Route key="Flashcard" path="/flashcard" element={<AuthGuard component={<Flashcard />} />} />,
    <Route key="Logeneration" path="/logeneration" element={<AuthGuard component={<LoGeneration />} />} />
]

export default AuthRoutes;