import { React } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'

const Back = () => {

    return (
        <div className="row">
            <div className="col mt-2">
                <a href="/" className="back_link"><span className="material-symbols-rounded">chevron_left</span> Back</a>
            </div>
        </div>
    )
}
export default Back;