import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import axios from 'axios';
import Loader from "../Loader"
import Header from "../Header/header";
import Back from "../Back/back";
import 'react-toastify/dist/ReactToastify.css';
import File_Uploader from '../FileUpload/fileupload';
import Toaster from "../Toaster/Toaster";

const SuggestiveKeyword = () => {
    const [text, setText] = useState('')
    const [loading, setLoading] = useState(false);
    const [data1, setData1] = useState(null);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');


    const formData = {
        text: text,
    }

    const resetData = async () => {
        setText('');
        setData1(null);
        setLoading(false);
    }
    const uploadedTextData = (text1) => {
        // setReadOnly(true);
        setText(text1);
    }

    const postData = async () => {
        if (formData.text == '') {
            setMode("error");
            setMessage("Please type or paste or select a file for processing.");
        } else {
            try {
                setLoading(true);
                await axios.post(process.env.REACT_APP_SUGGESTIVEKEYWORD_URL, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json, text/plain, /'
                    },
                }).then((response) => {
                    const data = response.data.data.suggestive_keywords;
                    if (data.length === 0) {
                        data.push('No keyword(s) found. Please validate the paragraph.')
                    }
                    setData1(data);
                    setLoading(false);
                    if (data && data.length > 0){
                        setMode("success");
                        setMessage("Please scroll down to see the generated result.");
                    }else{
                        setMode("error");
                        setMessage(process.env.REACT_APP_NO_DATA);
                    }
                });

            } catch (error) {
                setMode("error");
                setMessage("Error while process the request.");
            }
        }
    }


    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-sharp">spellcheck</span>
                            <h1>Suggestive Keywords</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Generate keywords using AI</p>
                        </div>
                    </div>
                    {loading ? <Loader /> : ""}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="wrapper-ai-case-content summarization_area" >
                                <div className="inputBox">
                                    <label htmlFor="question_drop">Add Content</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <textarea maxLength={process.env.REACT_APP_TEXT_LIMIT} className="form-control" value={text} placeholder="Paste your Text here" id="question_drop" style={{ "minHeight": "170px" }} onChange={(e) => setText(e.target.value)}></textarea>
                                        <div className="typedCount">You've typed {text.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>
                                        <div className="lines"><span>OR</span></div>
                                        <File_Uploader onFileUpload={uploadedTextData} />
                                    </div>
                                </div>
                                <div className="inputBox d-flex justify-content-end BtnBox">
                                    <button type="reset" className="btn btn-primary btn_reset" onClick={resetData}>Reset</button>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    {
                        data1 ? <div className="row mt-3">
                            <div className="col-sm-12">
                                <div className="wrapper-ai-case-content summarization_area" style={{ "minHeight": "280px" }} >
                                    <div className="inputBox">
                                        <p>Suggestive Keywords</p>
                                        <div className="keywords"  >
                                            <span className="keywords_block">{data1.join(", ")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                    }

                </div>
            </main>
            <Toaster message={message} mode={mode}/>
        </div>
    )
}

export default SuggestiveKeyword;
