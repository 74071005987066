import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import Header from "../Header/header";
import axios from 'axios';
import Loader from "../Loader";
import Back from "../Back/back";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import 'react-toastify/dist/ReactToastify.css';
import Toaster from "../Toaster/Toaster";


const ContextualAlttext = () => {
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [processedFile, setProcessedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [enableButton, setEnableButton] = React.useState(false);
    const [showResult, setShowResult] = React.useState(false);
    const [message, setMessage] = useState('');
    const [value, setValue] = useState("0");
    const [mode, setMode] = useState('success');
    useEffect(() => {
        const dropArea = document.querySelector(".drag-area"),
            dragText = dropArea.querySelector("h2");
        //If user Drag File Over DropArea
        dropArea.addEventListener("dragover", (event) => {
            event.preventDefault(); //preventing from default behaviour
            dropArea.classList.add("active");
            dragText.textContent = "Release to Upload File";
        });

        //If user leave dragged File from DropArea
        dropArea.addEventListener("dragleave", () => {
            dropArea.classList.remove("active");
            dragText.textContent = "Drag & Drop to Upload File";
        });

        //If user drop File on DropArea
        dropArea.addEventListener("drop", (event) => {
            event.preventDefault();
            setSelectedFile(event.dataTransfer.files[0]);
        });
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //image show
    const handleFileSelect = (e) => {
        setSelectedFile(e.target.files[0]);
    }
    //image upload
    const setSelectedFile = (file) => {
        setProcessedFile(null);
        setShowResult(false);
        if (file === undefined || (file.type !== "application/msword" && file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            setEnableButton(false);
        } else {
            setEnableButton(true);
            setSelectedDoc(file);
        }
    }
    const handleButton = () => {
        document.getElementById('image-input').click();
    }
    const generateContextualAlttext = async () => {
        if (selectedDoc == null) {
            setMode("error");
            setMessage("Please type or paste or select a file for processing.");
        } else {
            setLoading(true);

            const formData = new FormData();
            formData.append('doc_file', selectedDoc);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            await axios.post(process.env.REACT_APP_CONTEXTUAL_IMAGE_ALT_TEXT_URL, formData, config).then((response) => {
                let data = response.data.data;
                // let responseData = { name: selectedDoc.name, loc: data.loc, msg: data.msg, type: data.type };
                // setProcessedFile(responseData);
                setProcessedFile(data);
                if (data && data.length > 0){
                    setMode("success");
                    setMessage("Please scroll down to see the generated result.");
                }else{
                    setMode("error");
                    setMessage(process.env.REACT_APP_NO_DATA);
                }
                setShowResult(true);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setMode("error");
                setMessage("Error while process the request.")
            });
            setLoading(false);
        }
    }

    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-sharp">text_fields</span>
                            <h1>Contextual Alt Text</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Generate contextual alt text for any Docx file in a few clicks with this simple and effective tool!</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="content-drag-area file_drop">
                                <div className="drag-area">
                                    <div className="icon file_drop_icon">
                                        <button type="button"><span className="material-symbols-rounded" onClick={handleButton}>upload_file</span></button>
                                        <input type="file" id="image-input" accept="application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => handleFileSelect(e)} hidden />

                                    </div>
                                    <span>{selectedDoc?.name}</span>
                                    <h2>Select Doc file to Upload <span>-OR-</span> Drag and Drop Here</h2>
                                    <span>File must be Docx</span>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="container">
                            <div className="col">
                                <div className="inputBox d-flex justify-content-end BtnBox" style={{ "marginTop": "0" }}>
                                    <button type="button" className="btn btn-primary btn_generate" disabled={!enableButton} onClick={generateContextualAlttext}>Generate </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showResult && processedFile ?
                            <Box key="box" sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext key="TabContext" value={value.toString()}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList key="TabList" onChange={handleChange} aria-label="lab API tabs example">
                                            {processedFile.map((img, index) => (
                                                <Tab key={`Tab${index}`} label={`Topic: ${index + 1}`} value={index.toString()} />
                                            ))}
                                        </TabList>
                                    </Box>
                                    {processedFile.map((img, index) => (
                                        <TabPanel key={`TabPanel${index}`} value={index.toString()}>
                                            <div className="row mb-3" style={{ 'textAlign': 'center' }}>
                                                <div className="col-sm-12 ">
                                                    <div className="wrapper-ai-case-content video-wraper">
                                                        <div className="videoPlayer">
                                                            <img key={`image${index}`} src={`data:image/jpeg;base64,${img.image_base64}`} alt={`Uploaded ${index + 1}`} className="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <table className="table table-bordered" aria-labelledby="answer">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Topic</th>
                                                            <th scope="col">Pre Paragraph</th>
                                                            <th scope="col">Post Paragraph</th>
                                                            <th scope="col">Tag</th>
                                                            <th scope="col">Confidence Score(%)</th>
                                                            <th scope="col">Caption</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{img.topics}</td>
                                                            <td>{img.pre_paragraph}</td>
                                                            <td>{img.post_paragraph}</td>
                                                            <td>{img.tag}</td>
                                                            <td>{img.confidence_score}</td>
                                                            <td>{img.caption}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>

                                    ))}
                                </TabContext>
                            </Box>
                            : ""
                    }


                </div>

            </main>
            {
                loading ? <Loader /> : ""
            }
            <Toaster message={message} mode={mode} />
        </div>

    )


}
export default ContextualAlttext;