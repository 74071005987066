import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import axios from 'axios';
import Header from "../Header/header";
import Loader from "../Loader"
import Back from "../Back/back";
import 'react-toastify/dist/ReactToastify.css';
import Toaster from "../Toaster/Toaster";

const AutoGrading = () => {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');

    const formData = {
        question: 'What is personalized Learning?',
        submitted_answer: text,
    }


    const resetData = async () => {
        setText('');
        setData(null);
        setLoading(false);
    }

    const postData = async () => {
        try {
            if (formData.submitted_answer == '') {
                setMode("error");
                setMessage("Please type or paste or select a file as an your answer.");
            } else {
                setLoading(true);
                await axios.post(process.env.REACT_APP_AUTOGRADING_URL, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json, text/plain, /'
                    },
                }).then((response) => {
                    const data0 = response.data.data;
                    setData(data0);
                    setLoading(false);
                    if (data0){
                        setMode("success");
                        setMessage("Please scroll down to see the generated result.");
                    }else{
                        setMode("error");
                        setMessage(process.env.REACT_APP_NO_DATA);
                    }
                });
            }

        } catch (error) {
            console.log('error', error);
            setMode("error");
            setMessage("Error while process the request.")
            setLoading(false);
        }
    }

    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-rounded">&nbsp;</span>
                            <h1>Grading Assistant</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Get student responses to questions auto-graded by the AI tool.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="wrapper-ai-case-content summarization_area autograding-area" >
                                <div className="inputBox">
                                    <h3>Question</h3>
                                    <p onChange={(e) => setText(e.target.value)}>What is personalized Learning?</p>
                                    <textarea maxLength={process.env.REACT_APP_TEXT_LIMIT} className="form-control" value={text} placeholder="Write or paste your answer here..." style={{ "minHeight": "170px" }} onChange={(e) => setText(e.target.value)}></textarea>
                                    <div className="typedCount">You've typed {text.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>

                                </div>
                                <div className="inputBox d-flex justify-content-end BtnBox">
                                    <button type="reset" className="btn btn-primary btn_reset" onClick={resetData}>Reset</button>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Submit</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </main>
            {loading ? <Loader /> : ""}
            {
                data ? <div className="contents-part">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="wrapper-ai-case-content summarization_area autograding-area mt-3" >
                                    <div className="inputBox p-2">
                                        <h2>Generated Result</h2>
                                        <h3>Question</h3>
                                        <p >What is personalized Learning?</p>
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Submitted Answer</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Actual Answer</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                <h3>Overall score</h3>
                                                <div className="progreess_area">
                                                    <div className="prgress-area-block">
                                                        {/* <p>Contextual Similarity<span> {data.similar}%</span></p> */}
                                                        <p>Contextual Similarity<span> {data.contextual_similarity}%</span></p>
                                                        <div className="progress red">
                                                            <div className="progress-bar" role="progressbar" style={{ width: `${data.similar}%` }} aria-valuenow={data.similar} aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <div className="prgress-area-block">
                                                        <p>Spelling<span> {data.spelling}%</span></p>
                                                        <div className="progress blue">
                                                            <div className="progress-bar" role="progressbar" style={{ "width": `${data.spell}%` }} aria-valuenow={data.spell} aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <div className="prgress-area-block">
                                                        <p>Grammar sentence structure<span> {data.grammar_sentence_structure}%</span></p>
                                                        <div className="progress green">
                                                            <div className="progress-bar" role="progressbar" style={{ "width": `${data.grammer}%` }} aria-valuenow={data.grammer} aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <p>{text}</p>
                                            </div>
                                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                <p>{data.desired_answer}</p>
                                            </div>

                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div> : ""
            }

            <Toaster message={message} mode={mode} />
        </div>
    )
}
export default AutoGrading;
