import React, { useState, useEffect } from "react";
import { useGoogleLogin } from '@react-oauth/google';
import './login.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../images/LearningMate-logo.svg'
import AIonEducation from '../../images/AI-on-Education-696x368.jpg'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Recaptcha from "react-recaptcha";
import MicrosoftLogin from "react-microsoft-login";
import { useNavigate } from 'react-router-dom';


const Login = () => {
   const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    const [enableButton, setEnableButton] = React.useState(false);
    const [showErrorMessage, setErrorMessage] = React.useState(false);
    const navigate = useNavigate();

    const onError = (error) => {
        console.log(error)
    };

    const authHandler = (err, data) => {
        console.log(err, data);
    };

    useEffect(
        () => {
            AsyncStorage.getItem('status').then((status) => {
                if(status){
                    setProfile(null);
                    AsyncStorage.removeItem('status');
                    navigate("/");
                }
            });

            if (user && user.access_token) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then(async (res) => {
                        await AsyncStorage.setItem('profile', JSON.stringify(res.data));
                        setErrorMessage(false);
                        setProfile(res.data);
                        //sessionStorage.setItem('profile', res.data);
                        // const file = new Blob([JSON.stringify(res.data)], { type: 'text/plain;charset=utf-8' });
                        // saveAs(file, uuid.v4() + '.txt');
                        window.location = '/';
                    })
                    .catch((err) => console.log(err));
            }
        },
        [user]
    );

    // specifying your onload callback function
    const callback = function () {
        console.log('Done!!!!');
    };

    // specifying verify callback function
    const verifyCallback = function (response) {
        setEnableButton(true);
        //console.log(response);
    };

    const validateRecaptcha = () => {
        if (enableButton) {
            login();
        } else {
            setErrorMessage(true);
        }

    };

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError,
        isSignedIn: true
    });

    return (
        <div className="full-area">
            <header className="top-part">
                <img src={logo} alt="LearningMate" />
                <a href="#" className="btn_contactUs">Contact us</a>
            </header>
            <main className="loginpage-content">
                <img className="loginpage-banner" alt="" src={AIonEducation} />
                <div className="loginpage-conent">
                    <p>LM Ai uses the latest and most advanced artificial intelligence technology. With LM Ai you can create faster and better than ever before.</p>
                </div>
                <div className="recaptcha mt-3">
                    <Recaptcha
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        render="explicit"
                        verifyCallback={verifyCallback}
                        onloadCallback={callback}
                    />
                </div>
                {
                    showErrorMessage ? <div className="error-msg mt-3" >Please verify that you are a human!</div> : ""
                }

                <div className="google-btn mb" disabled={!enableButton} onClick={() => validateRecaptcha()}>
                    <div className="google-icon-wrapper">
                        <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
                    </div>
                    <p className="btn-text mt-4">Log In with Google</p>
                </div>
                <div style={{ 'textAlign': 'center', "display": "none" }} className="mt-3">
                    <MicrosoftLogin clientId="68530f66-39e7-4c18-a449-73324ac34741" authCallback={authHandler} />
                </div>
                <br />
            </main>


        </div>
    )
}
export default Login;